import axios, { CancelTokenSource } from "axios";

import { GET_AVAILABLE_ROOMS } from "../../services/endpoints";
import axiosAPI from "../../services/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Declare variables for cancel token and timer
let cancelTokenSource: CancelTokenSource;
let timerId: NodeJS.Timeout;

// Debounced function for API requests
const debouncedGetAvailableRooms = (
  params: {
    office: string;
    eventStartDateTime: number;
    eventEndDateTime: number;
  },
  thunkAPI: any
) =>
  new Promise(async (resolve, reject) => {
    // If there's a previous request, cancel it
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }

    // If there's a previous timer, clear it
    if (timerId) {
      clearTimeout(timerId);
    }

    // Set a new timer
    timerId = setTimeout(async () => {
      // Create a new cancel token for the new request
      cancelTokenSource = axios.CancelToken.source();

      try {
        const { office, eventStartDateTime, eventEndDateTime } = params;
        // Perform the API request
        const { data } = await axiosAPI.request({
          url: GET_AVAILABLE_ROOMS(
            office,
            eventStartDateTime,
            eventEndDateTime
          ),
          method: "GET",
          cancelToken: cancelTokenSource.token,
        });
        // If the request is successful, resolve the Promise with the response data
        resolve(data);
      } catch (error: any) {
        // If an error occurs, check if it's because the request was cancelled
        if (axios.isCancel(error)) {
          console.log("Request: ", error.message);
        } else {
          // If the error is not due to cancellation, reject the Promise with the error message
          if (error.response && error.response.data.message) {
            reject(error.response.data.message);
          } else {
            reject(error.message);
          }
        }
      }
    }, 500); // Debounce delay in milliseconds
  });

export const getAvailableRooms = createAsyncThunk(
  "rooms/getAvailable",
  async (
    params: {
      office: string;
      eventStartDateTime: number;
      eventEndDateTime: number;
    },
    thunkAPI
  ) => {
    try {
      // Use the debounced function for API call
      const data = await debouncedGetAvailableRooms(params, thunkAPI);
      return data as any;
    } catch (error) {
      // Handle errors from the debounced function
      return thunkAPI.rejectWithValue(error);
    }
  }
);
