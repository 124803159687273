import { useEffect, useState } from "react";

import DOMPurify from "dompurify";
import { Room } from "../types/room";
import _ from "lodash";

const useUpdateChangesCount = (
  formMethods: any,
  initialValues: any,
  placeholderFileName: string,
  event: any,
  eventLoading: boolean
) => {
  const [changesCount, setChangesCount] = useState(0);

  useEffect(() => {
    if (eventLoading) {
      return;
    }
    const updateChangesCount = () => {
      const data = formMethods.getValues();
      let newChangesCount = Object.keys(initialValues).reduce(
        (count: number, key: string) => {
          const keyAsString = key as keyof typeof initialValues;

          if (keyAsString === "description") {
            const initialDescription = initialValues[keyAsString] as string;
            const currentDescription = data[keyAsString] as string;

            let currentDescriptionString = `${DOMPurify.sanitize(
              currentDescription
            )}`;

            if (initialDescription === currentDescriptionString) {
              return count;
            }

            currentDescriptionString = `<div style="margin: 0;"><style>div > p { margin: 0; }</style>${DOMPurify.sanitize(
              currentDescription
            )}</div>`;

            if (!_.isEqual(initialDescription, currentDescriptionString)) {
              return count + 1;
            } else {
              return count;
            }
          }

          if (keyAsString === "recipientList") {
            const initialRecipientList = initialValues[keyAsString] as string[];
            const currentRecipientList = data[keyAsString] as string[];

            if (!_.isEqual(initialRecipientList, currentRecipientList)) {
              return count + 1;
            }
          }

          if (keyAsString === "room") {
            const initialRoom = initialValues[keyAsString] as
              | Room
              | null
              | undefined;
            const currentRoom = data[keyAsString] as Room | null | undefined;

            const normalizeRoom = (room: any) =>
              room === undefined ? null : room;

            const normalizedInitialRoom = normalizeRoom(initialRoom);
            const normalizedCurrentRoom = normalizeRoom(currentRoom);

            if (
              normalizedInitialRoom === null &&
              normalizedCurrentRoom === null
            ) {
              return count;
            }

            if (
              (normalizedInitialRoom === null &&
                normalizedCurrentRoom !== null) ||
              (normalizedInitialRoom !== null && normalizedCurrentRoom === null)
            ) {
              return count + 1;
            }

            if (!_.isEqual(normalizedInitialRoom, normalizedCurrentRoom)) {
              return count + 1;
            }
          }

          if (
            keyAsString !== "eventImageFile" &&
            !_.isEqual(data[keyAsString], initialValues[keyAsString])
          ) {
            return count + 1;
          }

          return count;
        },
        placeholderFileName === "FILE_CHANGED" ? 1 : 0
      );

      if (newChangesCount !== changesCount) {
        setChangesCount(newChangesCount);
      }
    };

    const subscription = formMethods.watch(() => updateChangesCount());
    return () => subscription.unsubscribe();
  }, [
    formMethods,
    initialValues,
    placeholderFileName,
    event,
    eventLoading,
    changesCount,
  ]);

  return changesCount;
};

export default useUpdateChangesCount;
