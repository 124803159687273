import * as Styled from "../CreateEventPage.styles";

import { Typography, useTheme } from "@mui/material";

import { ChipListInput } from "../../../components";

const PrimaryContactSection = ({ formMethods }: any) => {
  const theme = useTheme();

  return (
    <Styled.FormSectionContainer>
      <Typography variant="h3">Primary Contact(s)</Typography>
      <Typography
        variant="subtitle1"
        style={{
          color: theme.colors.neutral.darkGray,
          marginTop: theme.spacing(1),
        }}
      >
        Include the names of the employees responsible for coordinating this
        event by typing their name and hitting ENTER.
      </Typography>
      <ChipListInput
        name="primaryContacts"
        control={formMethods.control}
        label="Primary Contact(s)"
        required
        placeholder="Start typing a name..."
        fullWidth
      />
    </Styled.FormSectionContainer>
  );
};

export default PrimaryContactSection;
