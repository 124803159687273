import * as Styled from "./DraftEventPage.styles";

import { APIError, PageContainer } from "../../components";
import {
  Alert,
  Box,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DetailsSection,
  EventFeedbackSection,
  PrimaryContactSection,
  SignupSurveySection,
} from "../CreateEventPage/sections";
import {
  EDIT_EVENT,
  TRIGGER_EVENT_EMAIL_ACTION,
} from "../../services/endpoints";
import { EventOpsConsentModal, RoomFinderModal } from "../../modals";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Event } from "../../types/event";
import { NEW_EVENT_CREATED } from "../../store/actions/newEventActions";
import { Room } from "../../types/room";
import { RootState } from "../../store/store";
import { UserState } from "../../types/redux";
import axiosAPI from "../../services/axios";
import { combineDayJSToUnix } from "../../utils/utils";
import formOptions from "../CreateEventPage/formOptions";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { getDraftEvent } from "../../store/actions/draftEventActions";
import imageCompression from "browser-image-compression";
import { prepareEditEventData } from "../../utils/event_utils";
import rooms from "./rooms";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import useUpdateChangesCount from "../../hooks/useUpdateChangesCount";

type DraftEventForm = {
  signUpCreator: boolean;
  title: string;
  description: string;
  eventDate?: Dayjs | null;
  startDateTime?: Dayjs | null;
  endDateTime?: Dayjs | null;
  dressCode: string;
  admissionCost: number;
  eventImageFile: File | null;
  format: string;
  businessAreaTags: string[];
  groupTags: string[];
  teamTags: string[];
  location: string;
  transportationParkingDetails: string;
  primaryContacts: string[];
  includeTeamsLink: string;
  teamsLink?: string;
  feedbackLink: string;
  includeSignUpSurvey: string;
  signUpSurvey: { type: string; question: string }[];
  room: Room | null;
  recipientList: string[];
  isHostedAtSlalom: string;
  isRoomRequired: string;
};

const DraftEventPage = () => {
  const { id } = useParams();

  const environment = process.env.REACT_APP_ENVIRONMENT || "local";

  useEffect(() => {
    if (id && id !== "") {
      dispatch(getDraftEvent(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const event: Event = useSelector((state: any) => state.draft.data);
  const eventLoading: boolean = useSelector(
    (state: any) => state.draft.loading
  );
  const eventError: boolean = useSelector((state: any) => state.draft.error);

  const currentTime = dayjs();
  const roundToNearest30Minutes = (dateTime: Dayjs) => {
    const minutes = dateTime.minute();
    const roundedMinutes = Math.round(minutes / 30) * 30;
    return dateTime.startOf("hour").add(roundedMinutes, "minute");
  };

  const [initialValues, setInitialValues] = useState({});
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [placeholderFileName, setPlaceholderFileName] = useState("");
  const [alert, setAlert] = useState<any>(null);
  const [alertVisible, setAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createEventError, setCreateEventError] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isNavigating = useRef(false);

  useEffect(() => {
    if (location.state?.alert && !isNavigating.current) {
      setAlert(location.state.alert);
      setAlertVisible(true);
      isNavigating.current = true;
      setTimeout(() => {
        navigate(location.pathname, { replace: true, state: {} });

        isNavigating.current = false;
      }, 10000);
    } else {
      setAlert(null);
      setAlertVisible(false);
    }
  }, [location.state, navigate, location.pathname]);

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlertVisible(false);
        setAlert(null);
      }, 10000); // 10 seconds

      return () => clearTimeout(timer);
    }
  }, [alert]);

  const userInfo = useSelector((state: any) => {
    const userState = state.user as UserState;
    return userState;
  });

  const formMethods = useForm({
    defaultValues: {
      title: "",
      description: "",
      eventDate: dayjs(),
      startDateTime: roundToNearest30Minutes(currentTime),
      endDateTime: roundToNearest30Minutes(currentTime).add(30, "minute"),
      dressCode: "",
      admissionCost: 0,
      format: "",
      eventImageFile: null,
      businessAreaTags: [],
      groupTags: [],
      teamTags: [],
      signUpCreator: true,
      location: "",
      transportationParkingDetails: "",
      primaryContacts: [],
      includeTeamsLink: "no",
      teamsLink: "",
      feedbackLink: "",
      includeSignUpSurvey: "yes",
      signUpSurvey: [],
      room: null,
      isHostedAtSlalom: "yes",
      isRoomRequired: "no",
      recipientList: [],
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (event) {
      if (
        !loading &&
        (event.status !== "DRAFT" ||
          (userInfo.name !== event.eventOrganizerName &&
            userInfo.email !== event.eventOrganizerEmail)) &&
        id === event.eventId
      ) {
        navigate("/events", {
          state: {
            alert: {
              severity: "error",
              message:
                "The event you tried to access is either not a draft or you do not have permission to view it.",
            },
          },
        });
      }

      const setFormValue = (name: keyof DraftEventForm, value: any) => {
        formMethods.setValue(name, value);
        setInitialValues((prevValues) => ({ ...prevValues, [name]: value }));
      };

      setFormValue("title", event.title);
      setFormValue("description", event.description);
      setFormValue("eventDate", dayjs.unix(event.startDateTime));
      setFormValue("startDateTime", dayjs.unix(event.startDateTime));
      setFormValue("endDateTime", dayjs.unix(event.endDateTime));
      setFormValue("dressCode", event.dressCode);
      setFormValue("admissionCost", event.admissionCost);
      setFormValue("format", event.format);
      setFormValue("location", event.location);
      setFormValue("includeTeamsLink", event.teamsLink !== "" ? "yes" : "no");
      setFormValue("teamsLink", event.teamsLink);
      setFormValue("eventImageFile", null);
      setFormValue("businessAreaTags", event.businessAreaTags as never[]);
      setFormValue("groupTags", event.groupTags as never[]);
      setFormValue("teamTags", event.teamTags as never[]);
      setFormValue(
        "transportationParkingDetails",
        event.transportationParkingDetails
      );
      setFormValue("feedbackLink", event.feedbackLink);
      setFormValue("primaryContacts", event.primaryContacts as never[]);
      setFormValue(
        "includeSignUpSurvey",
        event.signUpSurvey.length > 0 ? "yes" : "no"
      );
      setFormValue("signUpSurvey", event.signUpSurvey);
      setFormValue(
        "room",
        rooms.find(
          (room) =>
            room.name === event.roomName && room.email === event.roomEmail
        )
      );
      setPlaceholderFileName(
        event.eventHeroS3Url ? `${event.eventId}-hero-image` : ""
      );
      setFormValue("isHostedAtSlalom", event.isHostedAtSlalom);
      setFormValue("isRoomRequired", event.isRoomRequired);
      setFormValue("recipientList", event.recipientList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const { errors } = formMethods.formState;
  const hasErrors = Object.keys(errors).length > 0;

  const { loading: roomsLoading } = useSelector(
    (state: RootState) => state.room
  );

  const includeSignUpSurvey = formMethods.watch("includeSignUpSurvey");
  const format = formMethods.watch("format");

  const [showRoomFinderModal, setShowRoomFinderModal] = useState(false);
  const selectedRoom = formMethods.watch("room");
  const isHostedAtSlalom = formMethods.watch("isHostedAtSlalom");
  const isRoomRequired = formMethods.watch("isRoomRequired");
  const [roomFinderError, setRoomFinderError] = useState(false);
  const [roomFinderErrorMessage, setRoomFinderErrorMessage] = useState("");

  useEffect(() => {
    if (isRoomRequired === "no") {
      formMethods.setValue("room", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoomRequired]);
  useEffect(() => {
    if (isHostedAtSlalom === "no") {
      formMethods.setValue("isRoomRequired", "no");
      formMethods.setValue("room", null);
      formMethods.setValue("location", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHostedAtSlalom]);

  useEffect(() => {
    if (format === "Virtual") {
      formMethods.setValue("isRoomRequired", "no");
      formMethods.setValue("isHostedAtSlalom", "no");
      formMethods.setValue("room", null);
      formMethods.setValue("location", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format]);

  const toISOString = (timestamp: number) =>
    new Date(timestamp * 1000).toISOString();

  const saveDraft = async () => {
    setLoading(true);

    const data: DraftEventForm = formMethods.getValues();

    if (!validateRoomRequirement(data)) return;

    const { startDateTime, endDateTime } = getDateTimes(data);
    const newEventHero = await handleImageCompression(data);

    const editEventData = prepareEditEventData(
      data,
      startDateTime,
      endDateTime,
      newEventHero,
      event,
      isRoomRequired,
      isHostedAtSlalom,
      placeholderFileName,
      userInfo
    );

    try {
      await axiosAPI.put(EDIT_EVENT(event.eventId), editEventData);
      dispatch(getAllEvents());
      dispatch(getAllUserEvents(userInfo.email));
      dispatch(getDraftEvent(event.eventId));
      setLoading(false);
      setAlertVisible(true);
      setAlert({
        severity: "success",
        message: "Your draft has successfully been saved!",
      });
      window.scroll(0, 0);
    } catch (error) {
      setCreateEventError(true);
    } finally {
      setLoading(false);
    }
  };

  const createEventEmailPayload = (
    {
      title,
      description,
      startDateTime,
      endDateTime,
      format,
      eventOrganizerName,
      eventOrganizerEmail,
      location,
      recipientList,
    }: Event,
    room: Room | null
  ) => {
    const isInPersonOrHybrid = format === "In Person" || format === "Hybrid";

    return {
      action: "create",
      eventTitle: title,
      eventDescription: description,
      eventStartDateTime: toISOString(startDateTime),
      eventEndDateTime: toISOString(endDateTime),
      roomLocation: isInPersonOrHybrid ? room?.email || "" : "",
      eventInvitees: recipientList.join(";"),
      eventOrganizer: eventOrganizerName,
      eventOrganizerEmail: eventOrganizerEmail,
      eventFormat: format,
      eventImage: "",
      eventLocation: isInPersonOrHybrid ? room?.name || location : location,
    };
  };

  const sendEventEmail = async (event: Event, room: Room | null) => {
    const payload = createEventEmailPayload(event, room);
    await axiosAPI.post(TRIGGER_EVENT_EMAIL_ACTION(event.eventId), payload);
  };

  const publishDraft = async () => {
    setLoading(true);

    const data: DraftEventForm = formMethods.getValues();

    if (!validateRoomRequirement(data)) return;

    const { startDateTime, endDateTime } = getDateTimes(data);
    const newEventHero = await handleImageCompression(data);

    const editEventData = prepareEditEventData(
      data,
      startDateTime,
      endDateTime,
      newEventHero,
      event,
      isRoomRequired,
      isHostedAtSlalom,
      placeholderFileName,
      userInfo,
      "ACTIVE"
    );

    try {
      const response = await axiosAPI.put(
        EDIT_EVENT(event.eventId),
        filterUndefinedValues(editEventData)
      );
      const { result }: { result: Event } = response.data;
      await sendEventEmail(result, data.room);
      dispatch(getAllEvents());
      dispatch(getAllUserEvents(userInfo.email));
      setLoading(false);
      window.scroll(0, 0);
      dispatch({
        type: NEW_EVENT_CREATED,
        payload: { id: response.data.eventId },
      });
      navigate("/events");
    } catch (error: any) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const validateRoomRequirement = (data: any) => {
    if (
      data.isHostedAtSlalom === "yes" &&
      data.isRoomRequired === "yes" &&
      !data.room
    ) {
      setRoomFinderError(true);
      setRoomFinderErrorMessage(
        "Please select a room for this event or mark 'Would you like to request a room for this event to be in?' as no."
      );
      return false;
    }
    return true;
  };

  const getDateTimes = (data: any) => {
    const startDateTime = combineDayJSToUnix(
      data.eventDate as Dayjs,
      data.startDateTime as Dayjs
    );
    const endDateTime = combineDayJSToUnix(
      data.eventDate as Dayjs,
      data.endDateTime as Dayjs
    );
    return { startDateTime, endDateTime };
  };

  const handleImageCompression = async (data: any) => {
    if (data.eventImageFile && placeholderFileName === "FILE_CHANGED") {
      const options = {
        maxSizeMB: 3.0,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(
          data.eventImageFile as File,
          options
        );
        return await imageCompression.getDataUrlFromFile(compressedFile);
      } catch (error) {
        console.error("Image compression failed:", error);
      }
    }
    return undefined;
  };

  const filterUndefinedValues = (data: any) => {
    return Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v !== undefined)
    );
  };

  const handleError = (error: any) => {
    const errorMessage = error.response?.data?.data;

    if (errorMessage.includes("Room is not available for the specified time")) {
      setRoomFinderError(true);
      setRoomFinderErrorMessage(
        "The room that you have selected is no longer available. Please try again."
      );
    } else {
      setCreateEventError(true);
      console.error("Failed to update event:", error);
    }
  };

  const onSubmit: SubmitHandler<DraftEventForm> = async (data) => {
    if (
      data.isHostedAtSlalom === "yes" &&
      data.isRoomRequired === "yes" &&
      !data.room
    ) {
      setRoomFinderError(true);
      setRoomFinderErrorMessage(
        "Please select a room for this event or mark 'Would you like to request a room for this event to be in?' as no."
      );
    } else {
      setShowConsentModal(true);
    }
  };

  const changesCount = useUpdateChangesCount(
    formMethods,
    initialValues,
    placeholderFileName,
    event,
    eventLoading
  );

  if (eventLoading) {
    return (
      <PageContainer>
        <Styled.PageLoaderContainer>
          <CircularProgress />
        </Styled.PageLoaderContainer>
      </PageContainer>
    );
  }

  if (eventError && !eventLoading) {
    return <APIError />;
  }

  return (
    <PageContainer>
      {event && (
        <RoomFinderModal
          name={"room"}
          control={formMethods.control}
          setValue={formMethods.setValue}
          open={showRoomFinderModal}
          onClose={() => {
            setShowRoomFinderModal(false);
          }}
          createdEvent={event}
          roomFinderError={roomFinderError}
          roomFinderErrorMessage={roomFinderErrorMessage}
          setRoomFinderError={setRoomFinderError}
          setRoomFinderErrorMessage={setRoomFinderErrorMessage}
        />
      )}

      <EventOpsConsentModal
        open={showConsentModal}
        onClose={() => {
          setShowConsentModal(false);
        }}
        onConfirm={publishDraft}
      />
      {createEventError ? (
        <APIError
          onClick={() => {
            setCreateEventError(false);
          }}
        />
      ) : (
        <Styled.PaddedContainer>
          {alertVisible && alert && (
            <Alert
              severity={alert.severity}
              sx={{ marginBottom: theme.spacing(2) }}
              onClose={() => {
                setAlertVisible(false);
                setAlert(false);
              }}
            >
              {alert.message}
            </Alert>
          )}
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Styled.HeaderStack
                direction={{ mobile: "column", desktop: "row" }}
                width="100%"
              >
                <Styled.HeaderText variant="h1" gutterBottom>
                  Manage Draft Event
                </Styled.HeaderText>
              </Styled.HeaderStack>
              <Typography variant="body2">
                This event is currently in draft mode. Once you’ve made your
                changes, you can either save the draft to continue later or
                publish the event. To publish, ensure all required fields are
                completed and then click "Publish."
              </Typography>
              <DetailsSection
                formMethods={formMethods}
                formOptions={formOptions}
                environment={environment}
                event={event}
                format={format}
                roomFinderError={roomFinderError}
                roomFinderErrorMessage={roomFinderErrorMessage}
                selectedRoom={selectedRoom}
                roomsLoading={roomsLoading}
                setShowRoomFinderModal={setShowRoomFinderModal}
                placeholderFileName={placeholderFileName}
                setPlaceholderFileName={setPlaceholderFileName}
                isHostedAtSlalom={isHostedAtSlalom}
                isRoomRequired={isRoomRequired}
                userInfo={userInfo}
                eventDescription={event?.description}
              />
              <PrimaryContactSection formMethods={formMethods} />
              <SignupSurveySection
                formMethods={formMethods}
                includeSignUpSurvey={includeSignUpSurvey}
              />
              <EventFeedbackSection formMethods={formMethods} />
              {loading ? (
                <Styled.LoaderContainer>
                  <CircularProgress />
                </Styled.LoaderContainer>
              ) : (
                <Box>
                  <Box
                    display="flex"
                    justifyContent={{ mobile: "center", desktop: "end" }}
                  >
                    {hasErrors && (
                      <Styled.ErrorMessage>
                        Please correct all errors before publishing the event
                      </Styled.ErrorMessage>
                    )}
                  </Box>
                  <Styled.FormActionStack
                    direction={{ mobile: "column", desktop: "row-reverse" }}
                    spacing={theme.spacing(4)}
                    width="100%"
                    alignItems="flex-end"
                  >
                    <Styled.ActionButton
                      variant="contained"
                      type="submit"
                      disabled={
                        (roomsLoading || roomFinderError) &&
                        isRoomRequired === "yes"
                      }
                    >
                      Publish Event
                    </Styled.ActionButton>
                    <Styled.ActionButton
                      variant="outlined"
                      disabled={
                        ((roomsLoading || roomFinderError) &&
                          isRoomRequired === "yes") ||
                        changesCount === 0 ||
                        hasErrors
                      }
                      onClick={saveDraft}
                    >
                      Save Changes ({changesCount})
                    </Styled.ActionButton>
                  </Styled.FormActionStack>
                </Box>
              )}
            </form>
          </FormProvider>
        </Styled.PaddedContainer>
      )}
    </PageContainer>
  );
};

export default DraftEventPage;
