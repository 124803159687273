import * as Styled from "../CreateEventPage.styles";

import { FormBuilder, RadioGroup } from "../../../components";
import { Typography, useTheme } from "@mui/material";

const SignupSurveySection = ({ formMethods, includeSignUpSurvey }: any) => {
  const theme = useTheme();

  return (
    <Styled.FormSectionContainer>
      <Typography variant="h3">Signup Survey</Typography>
      <Typography
        variant="body2"
        style={{
          marginTop: theme.spacing(1),
        }}
      >
        Do you want to ask any specific questions to attendees upon signup for
        this event?
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          color: theme.colors.neutral.darkGray,
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
      >
        For example, “Do you have any dietary restrictions?”
      </Typography>
      <RadioGroup
        control={formMethods.control}
        name="includeSignUpSurvey"
        label=""
        row
        options={[
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ]}
      />
      {includeSignUpSurvey === "yes" && (
        <FormBuilder control={formMethods.control} formName="signUpSurvey" />
      )}
    </Styled.FormSectionContainer>
  );
};

export default SignupSurveySection;
