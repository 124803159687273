import * as Styled from "../CreateEventPage.styles";

import { Typography, useTheme } from "@mui/material";

import { Input } from "../../../components";

const EventFeedbackSection = ({ formMethods }: any) => {
  const theme = useTheme();

  return (
    <Styled.FormSectionContainer>
      <Typography variant="h3">Event Feedback</Typography>
      <Typography
        variant="subtitle1"
        style={{
          color: theme.colors.neutral.darkGray,
          marginTop: theme.spacing(1),
        }}
      >
        Please generate and paste a link to a survey or feedback form (ex.
        SparkThink) for attendees to fill out during/after the event.
      </Typography>
      <Input
        name="feedbackLink"
        control={formMethods.control}
        label="Feedback Link"
        placeholder="Enter feedback link..."
        fullWidth
        forLink
      />
    </Styled.FormSectionContainer>
  );
};

export default EventFeedbackSection;
