import * as Styled from "./DetailsTab.styles";

import { Box, Stack, Typography } from "@mui/material";
import {
  getAmOrPm,
  getHourFromMilliseconds,
  getMonthAndDayFromMilliseconds,
  nthNumber,
} from "../../../../utils/utils";

import { Contact } from "../../../../components";
import DOMPurify from "dompurify";
import { Event } from "../../../../types/event";
import { GatherDefaultImage } from "../../../../assets";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import { createSelector } from "@reduxjs/toolkit";
import theme from "../../../../theme/theme";
import { useSelector } from "react-redux";

const DetailsTab = () => {
  const eventLoading = useSelector(
    createSelector(
      (state: any) => {
        return state.liveEvent.loading;
      },
      (loading: boolean) => {
        return loading;
      }
    )
  );

  const event = useSelector(
    createSelector(
      (state: any) => {
        return state.liveEvent.data;
      },
      (event: Event) => {
        return event;
      }
    )
  );

  return (
    <Styled.PaddedContainer>
      {eventLoading || !event ? (
        <Stack
          direction="column"
          width="100%"
          height="100vh"
          style={{
            marginTop: theme.spacing(2),
          }}
        >
          <Stack
            direction={{ mobile: "column", desktop: "row" }}
            width="100%"
            height="100%"
            spacing={{ mobile: 2, desktop: 2 }}
          >
            <Styled.SkeletonContent variant="rectangular" />
            <Styled.SkeletonContent variant="rectangular" />
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction="column"
          width="100%"
          style={{
            height: "100%",
            marginTop: theme.spacing(2),
          }}
        >
          <Stack
            direction={{ mobile: "column", desktop: "row" }}
            width="100%"
            spacing={{ mobile: 0, desktop: 6 }}
          >
            <Styled.DetailsContainer>
              <Styled.HeroContainer>
                <Styled.HeroBackground
                  style={{
                    backgroundImage: `url("${
                      event.eventHeroS3Url || GatherDefaultImage
                    }")`,
                    filter: event.eventHeroS3Url
                      ? "blur(50px) brightness(.9)"
                      : "",
                  }}
                ></Styled.HeroBackground>
                <Styled.HeroImage
                  src={event.eventHeroS3Url || GatherDefaultImage}
                  alt={event.title}
                />
              </Styled.HeroContainer>
              <Box>
                <Typography
                  variant="modalSubtitle"
                  style={{
                    marginBottom: theme.spacing(1),
                  }}
                >
                  Details
                </Typography>
                <Styled.ModalBodyMB2 variant="modalBody">
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(event.description),
                    }}
                  />
                </Styled.ModalBodyMB2>
                <Stack direction={"row"} width="100%">
                  <Box style={{ width: "50%" }}>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: theme.colors.neutral.darkGray,
                      }}
                    >
                      Date
                    </Typography>
                    <Styled.ModalBodyMB2 variant="modalBody">
                      {getMonthAndDayFromMilliseconds(event.startDateTime)}
                      <Styled.SuperScript>
                        {nthNumber(event.startDateTime)}
                      </Styled.SuperScript>
                    </Styled.ModalBodyMB2>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: theme.colors.neutral.darkGray,
                      }}
                    >
                      Dress Code
                    </Typography>
                    <Styled.ModalBodyMB2 variant="modalBody">
                      {event.dressCode || "N/A"}
                    </Styled.ModalBodyMB2>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: theme.colors.neutral.darkGray,
                      }}
                    >
                      Format
                    </Typography>
                    <Styled.ModalBodyMB2 variant="modalBody">
                      {event.format}
                    </Styled.ModalBodyMB2>
                  </Box>
                  <Box style={{ width: "50%" }}>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: theme.colors.neutral.darkGray,
                      }}
                    >
                      Time
                    </Typography>
                    <Styled.ModalBodyMB2 variant="modalBody">
                      {`${getHourFromMilliseconds(event.startDateTime)}`}
                      <Styled.SuperScript>
                        {getAmOrPm(event.startDateTime)}
                      </Styled.SuperScript>
                      {" - "}
                      {getHourFromMilliseconds(event.endDateTime)}
                      <Styled.SuperScript>
                        {getAmOrPm(event.endDateTime)}
                      </Styled.SuperScript>
                    </Styled.ModalBodyMB2>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: theme.colors.neutral.darkGray,
                      }}
                    >
                      Cost
                    </Typography>
                    <Styled.ModalBodyMB2 variant="modalBody">
                      {event.admissionCost
                        ? `$${event.admissionCost.toString()}`
                        : "N/A"}
                    </Styled.ModalBodyMB2>
                    {event.teamsLink !== "" && (
                      <>
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: theme.colors.neutral.darkGray,
                          }}
                        >
                          Teams Meeting Link
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            transition: "opacity 0.3s ease",
                            "&:hover": {
                              opacity: 0.7,
                            },
                          }}
                          onClick={() => {
                            window.open(event.teamsLink, "_blank");
                          }}
                        >
                          <MicrosoftIcon
                            color="primary"
                            sx={{ width: "14px", height: "14px" }}
                          />
                          <Typography
                            variant="modalBody"
                            style={{
                              marginLeft: theme.spacing(1),
                              marginRight: theme.spacing(1),
                              color: theme.colors.primary.slalomBlue,
                              textDecoration: "underline",
                            }}
                          >
                            Join In Teams
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </Stack>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: theme.colors.neutral.darkGray,
                  }}
                >
                  {"Business Area(s)"}
                </Typography>
                <Styled.ModalBodyMB2 variant="modalBody">
                  {event.businessAreaTags.join(", ")}
                </Styled.ModalBodyMB2>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: theme.colors.neutral.darkGray,
                  }}
                >
                  {"Group(s)"}
                </Typography>
                <Styled.ModalBodyMB2 variant="modalBody">
                  {event.groupTags.join(", ")}
                </Styled.ModalBodyMB2>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: theme.colors.neutral.darkGray,
                  }}
                >
                  {"Team(s)"}
                </Typography>
                <Styled.ModalBodyMB2 variant="modalBody">
                  {event.teamTags.join(", ")}
                </Styled.ModalBodyMB2>
              </Box>
            </Styled.DetailsContainer>
            <Styled.LocationContainer>
              <Typography
                variant="modalSubtitle"
                style={{
                  marginBottom: theme.spacing(2),
                }}
              >
                Location
              </Typography>
              {event.format !== "Virtual" && (
                <Styled.MapContainer
                  loading="lazy"
                  src={`https://www.google.com/maps/embed/v1/place?key=${
                    process.env.REACT_APP_GOOGLE_API_KEY || ""
                  }
                    &q=${
                      event.roomName !== "" && event.roomEmail !== ""
                        ? "399 Boylston St #1000, Boston, MA 02116"
                        : event.location
                    }`}
                ></Styled.MapContainer>
              )}
              <Typography
                variant="subtitle2"
                style={{
                  color: theme.colors.neutral.darkGray,
                }}
              >
                Event Location
              </Typography>
              <Typography
                variant="modalBody"
                style={{
                  marginBottom: theme.spacing(2),
                }}
              >
                {event.format === "Virtual" ? "Virtual" : event.location}
              </Typography>
              {event.format !== "Virtual" && (
                <Typography
                  variant="subtitle2"
                  style={{
                    color: theme.colors.neutral.darkGray,
                  }}
                >
                  Transportation & Parking Details
                </Typography>
              )}
              {event.format !== "Virtual" && (
                <Styled.ModalBodyMB2 variant="modalBody">
                  {event.transportationParkingDetails || "N/A"}
                </Styled.ModalBodyMB2>
              )}
              <Typography
                variant="modalSubtitle"
                style={{
                  marginBottom: theme.spacing(2),
                }}
              >
                Primary Contact(s)
              </Typography>
              {event.primaryContacts.map((name: string) => {
                return (
                  <Contact
                    size={24}
                    spacing={theme.spacing(1)}
                    variant="modalBody"
                    name={name}
                    key={name}
                  />
                );
              })}
            </Styled.LocationContainer>
          </Stack>
        </Stack>
      )}
    </Styled.PaddedContainer>
  );
};

export default DetailsTab;
