import {
  Checkbox,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";

import { Controller } from "react-hook-form";
import { isURL } from "validator";

type InputProps = {
  name: string;
  control: any;
  required?: boolean;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  selectOptions?: { label: string; value: string }[];
  multiple?: boolean;
  forLink?: boolean;
  removeMarginTop?: boolean;
};

const Input = ({
  name,
  control,
  label,
  required = false,
  autoComplete = "off",
  placeholder,
  variant = "outlined",
  multiline = false,
  fullWidth = false,
  select = false,
  multiple = false,
  selectOptions,
  sx,
  startAdornment,
  endAdornment,
  inputProps,
  forLink = false,
  removeMarginTop = false,
}: InputProps & TextFieldProps) => {
  const theme = useTheme();

  // Function to format the label and apply color to text in parentheses
  const formatLabel = (label: string) => {
    const parts = label.split(/(\(.*?\))/g); // Split text into parts based on parentheses
    return parts.map((part, index) => (
      <span
        key={index}
        style={{
          color:
            part.startsWith("(") && part.endsWith(")")
              ? theme.colors.neutral.darkGray // Apply color to text inside parentheses
              : "inherit",
        }}
      >
        {part}
      </span>
    ));
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: `${label} is required` },
        validate:
          required && forLink
            ? (value) =>
                isURL(value, {
                  protocols: ["http", "https"],
                  require_protocol: true,
                }) || "Invalid URL"
            : undefined,
      }}
      render={({ field: { onChange, value = "" }, fieldState: { error } }) => (
        <TextField
          helperText={error?.message}
          autoComplete={autoComplete}
          error={!!error}
          onChange={(event) => {
            let newValue;
            if (multiline) {
              newValue = event.target.value;
            } else if (multiple) {
              newValue = event.target.value;
            } else {
              newValue =
                typeof event.target.value === "string"
                  ? event.target.value.replace(/\n/g, "")
                  : event.target.value;
            }
            onChange(newValue);
          }}
          value={value}
          fullWidth={fullWidth}
          label={`${label}${required ? "*" : ""}`}
          multiline={multiline}
          variant={variant}
          inputProps={{
            ...inputProps,
            style: {
              whiteSpace: multiline ? "pre-wrap" : "normal",
            },
          }}
          placeholder={placeholder}
          InputProps={{
            startAdornment: startAdornment,
            endAdornment: endAdornment,
            style: {
              color: theme.colors.neutral.black,
              backgroundColor: theme.colors.neutral.white,
            },
          }}
          SelectProps={{
            multiple: multiple,
            renderValue: (selected: any) => {
              if (multiple) {
                return (
                  <Typography component="span">
                    {selected
                      .map((value: string) => {
                        const option = selectOptions?.find(
                          (option) => option.value === value
                        );
                        return option ? formatLabel(option.label) : value;
                      })
                      .reduce((acc: any, curr: any) => (
                        <>
                          {acc}, {curr}
                        </>
                      ))}
                  </Typography>
                );
              } else {
                const option = selectOptions?.find(
                  (option) => option.value === selected
                );
                return option ? (
                  <Typography component="span">
                    {formatLabel(option.label)}
                  </Typography>
                ) : (
                  selected
                );
              }
            },
            MenuProps: {
              sx: {
                "& .MuiPaper-root": {
                  boxShadow: `0px 6px 20px 0px ${theme.colors.action.hoverShadow}`,
                },
              },
              style: {
                maxHeight: 500,
              },
            },
          }}
          rows={multiline ? 6 : 1}
          select={select}
          sx={{
            ...sx,
            marginTop: removeMarginTop ? theme.spacing(0) : theme.spacing(3),
            marginBottom: theme.spacing(0),
          }}
        >
          {select &&
            selectOptions &&
            selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {multiple && (
                  <Checkbox checked={value.indexOf(option.value) > -1} />
                )}
                <Typography component="span">
                  {formatLabel(option.label)}
                </Typography>
              </MenuItem>
            ))}
        </TextField>
      )}
    />
  );
};

export default Input;
