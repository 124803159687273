import { Box, Button, Card, CardMedia, Stack, Typography } from "@mui/material";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import { styled } from "@mui/material/styles";

export const CalendarIcon = styled(CalendarMonthIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.colors.primary.slalomBlue,
}));

export const DateColumn = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "50%",
}));

export const EventDescriptionContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  maxHeight: theme.spacing(6),
  overflow: "hidden",
}));

export const EventDescription = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral.darkGray,
  marginBottom: theme.spacing(3),
  display: "-webkit-box",
  overflow: "hidden",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  paddingBottom: theme.spacing(2),
}));

export const EventDetailsRow = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  alignItems: "center",
}));

export const EventCard = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "auto",
  minHeight: "354px",
  backgroundColor: "transparent",
  color: theme.colors.neutral.black,
  borderRadius: "8px",
  overflow: "hidden",
  position: "relative",
  "&:hover .editButton": {
    opacity: 1,
  },
  "&:hover": {
    boxShadow: `0px 6px 20px 0px ${theme.colors.action.hoverShadow}`,
  },
  cursor: "pointer",
}));

export const EventCardMedia = styled(CardMedia)(() => ({
  height: 200,
}));

export const SignUpsIcon = styled(GroupIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.colors.secondary.purple,
}));

export const EditEventIcon = styled(EditIcon)(({ theme }) => ({
  marginRight: "-8px",
  color: theme.colors.neutral.white,
}));

export const SignUpsColumn = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "50%",
}));

export const SuperScript = styled("sup")(() => ({
  position: "relative",
  fontSize: 10,
  top: 2.3,
}));

export const EventDetailsTitle = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  overflow: "hidden",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
}));

export const EventCardMediaContainer = styled(Box)(({ theme }) => ({
  position: "relative",
}));

export const LiveEventBannerWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "10%",
  right: "0%",
}));

export const LiveEventBanner = styled(Box)(({ theme }) => ({
  height: "24px",
  gap: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 16px",
  color: theme.colors.neutral.white,
  backgroundColor: theme.colors.secondary.coralRed,
  clipPath: "polygon(100% 0, 0 0, 10% 50%, 0 100%, 100% 100%)",
}));

export const EditButton = styled(Button)(({ theme }) => ({
  width: "75px",
  gap: "8px",
  color: theme.colors.neutral.white,
  height: "40px",
  borderRadius: theme.spacing(1),
  padding: "10px 12px",
  backgroundColor: theme.colors.primary.slalomBlue,
  position: "absolute",
  bottom: "10%",
  right: "5%",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: theme.colors.primary.slalomDarkBlue,
  },
}));
