const rooms = [
  {
    name: "_BOS 03 Barton [4P/AV]",
    floor: 3,
    email: "bosbarton@slalom.com",
    capacity: 4,
    office: "Boston",
  },
  {
    name: "_BOS 03 Child [12P/AV/MTR]",
    floor: 3,
    email: "boschild@slalom.com",
    capacity: 12,
    office: "Boston",
  },
  {
    name: "_BOS 03 Gardner [14P/AV/MTR]",
    floor: 3,
    email: "bos03gardner@slalom.com",
    capacity: 14,
    office: "Boston",
  },
  {
    name: "_BOS 03 Kennedy [12P/AV/MTR]",
    floor: 3,
    email: "boskennedy@slalom.com",
    capacity: 12,
    office: "Boston",
  },
  {
    name: "_BOS 03 Dubois [4P/AV]",
    floor: 3,
    email: "bosdubois@slalom.com",
    capacity: 4,
    office: "Boston",
  },
  {
    name: "_BOS 03 Fuller [6P/AV/MTR]",
    floor: 3,
    email: "bosfuller@slalom.com",
    capacity: 6,
    office: "Boston",
  },
  {
    name: "_BOS 03 Morse [6P/AV]",
    floor: 3,
    email: "bosmorse@slalom.com",
    capacity: 6,
    office: "Boston",
  },
  {
    name: "_BOS 03 Spock [6P/AV/MTR]",
    floor: 3,
    email: "bosspock@slalom.com",
    capacity: 6,
    office: "Boston",
  },
  {
    name: "_BOS 10 Gillette [2P]",
    floor: 10,
    email: "bosgillette@slalom.com",
    capacity: 2,
    office: "Boston",
  },
  {
    name: "_BOS 10 The Cafe [30P/AV/MTR]",
    floor: 10,
    email: "bos10thecafe@slalom.com",
    capacity: 30,
    office: "Boston",
  },
  {
    name: "_BOS 10 The Common [5P/AV/MTR]",
    floor: 10,
    email: "bosthecommon@slalom.com",
    capacity: 5,
    office: "Boston",
  },
  {
    name: "_BOS 10 Annisquam [12P/AV/MTR]",
    floor: 10,
    email: "bosannisquam@slalom.com",
    capacity: 12,
    office: "Boston",
  },
  {
    name: "_BOS 10 Arlington [4P/AV]",
    floor: 10,
    email: "bosarlington@slalom.com",
    capacity: 4,
    office: "Boston",
  },
  {
    name: "_BOS 10 Berkeley [4P/AV]",
    floor: 10,
    email: "bosberkeley@slalom.com",
    capacity: 4,
    office: "Boston",
  },
  {
    name: "_BOS 10 Fenway [4P]",
    floor: 10,
    email: "bosfenway@slalom.com",
    capacity: 4,
    office: "Boston",
  },
  {
    name: "_BOS 10 Harvard Yard [26P/Dual AV/MTR]",
    floor: 10,
    email: "bosharvardyard@slalom.com",
    capacity: 26,
    office: "Boston",
  },
  {
    name: "_BOS 10 Hereford [6P/AV/MTR]",
    floor: 10,
    email: "boshereford@slalom.com",
    capacity: 6,
    office: "Boston",
  },
  {
    name: "_BOS 10 Longfellow [6P/AV/MTR]",
    floor: 10,
    email: "boslongfellow@slalom.com",
    capacity: 6,
    office: "Boston",
  },
  {
    name: "_BOS 10 Minots [5P/AV/MTR]",
    floor: 10,
    email: "bosminots@slalom.com",
    capacity: 5,
    office: "Boston",
  },
  {
    name: "_BOS 10 Nauset [5P/AV/MTR]",
    floor: 10,
    email: "bosnauset@slalom.com",
    capacity: 5,
    office: "Boston",
  },
  {
    name: "_BOS 10 The Garden [2P]",
    floor: 10,
    email: "bosthegarden@slalom.com",
    capacity: 2,
    office: "Boston",
  },
  {
    name: "_BOS 10 Zakim [6P/AV/MTR]",
    floor: 10,
    email: "boszakim@slalom.com",
    capacity: 6,
    office: "Boston",
  },
];
export default rooms;
