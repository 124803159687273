import * as Styled from "./CreateEventPage.styles";

import { APIError, Checkbox, PageContainer } from "../../components";
import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CREATE_EVENT,
  TRIGGER_EVENT_EMAIL_ACTION,
} from "../../services/endpoints";
import {
  ClearFormModal,
  DraftEventsModal,
  EventOpsConsentModal,
  RoomFinderModal,
} from "../../modals";
import {
  DetailsSection,
  EventFeedbackSection,
  PrimaryContactSection,
  SignupSurveySection,
} from "./sections";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

import DOMPurify from "dompurify";
import { Event } from "../../types/event";
import { NEW_EVENT_CREATED } from "../../store/actions/newEventActions";
import { Room } from "../../types/room";
import { RootState } from "../../store/store";
import { UserState } from "../../types/redux";
import axiosAPI from "../../services/axios";
import { combineDayJSToUnix } from "../../utils/utils";
import formOptions from "./formOptions";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import imageCompression from "browser-image-compression";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

type CreateEventForm = {
  signUpCreator: boolean;
  title: string;
  description: string;
  eventDate?: Dayjs | null;
  startDateTime?: Dayjs | null;
  endDateTime?: Dayjs | null;
  dressCode: string;
  admissionCost: number;
  eventImageFile: File | null;
  format: string;
  businessAreaTags: string[];
  groupTags: string[];
  teamTags: string[];
  isHostedAtSlalom: string;
  isRoomRequired: string;
  room: Room | null;
  location: string;
  transportationParkingDetails: string;
  primaryContacts: string[];
  includeTeamsLink: string;
  teamsLink?: string;
  feedbackLink: string;
  includeSignUpSurvey: string;
  signUpSurvey: { type: string; question: string }[];
  recipientList: string[];
};

const CreateEventPage = () => {
  const currentTime = dayjs();
  const environment = process.env.REACT_APP_ENVIRONMENT || "local";
  const roundToNearest30Minutes = (dateTime: Dayjs) => {
    const minutes = dateTime.minute();
    const roundedMinutes = Math.round(minutes / 30) * 30;
    return dateTime.startOf("hour").add(roundedMinutes, "minute");
  };

  const userInfo = useSelector((state: any) => {
    const userState = state.user as UserState;
    return userState;
  });

  const selectUserEventsDraftLoading = useSelector(
    (state: RootState) => state.userEvents.loading
  );

  const selectUserEventsDraft = useSelector(
    (state: RootState) => state.userEvents.draft
  );

  const { loading: roomsLoading } = useSelector(
    (state: RootState) => state.room
  );

  const formMethods = useForm({
    defaultValues: {
      title: "",
      description: "",
      eventDate: dayjs(),
      startDateTime: roundToNearest30Minutes(currentTime),
      endDateTime: roundToNearest30Minutes(currentTime).add(30, "minute"),
      dressCode: "",
      admissionCost: 0,
      format: "",
      eventImageFile: null,
      businessAreaTags: [],
      groupTags: [],
      teamTags: [],
      signUpCreator: true,
      location: "",
      transportationParkingDetails: "",
      primaryContacts: [userInfo.name],
      includeTeamsLink: "no",
      teamsLink: "",
      feedbackLink: "",
      includeSignUpSurvey: "no",
      signUpSurvey: [],
      room: null,
      isHostedAtSlalom: "yes",
      isRoomRequired: "no",
      recipientList: [],
    },
    mode: "onChange",
  });
  const [showClearFormModal, setShowClearFormModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createEventError, setCreateEventError] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showDraftEventsModal, setShowDraftEventsModal] = useState(false);
  const [showRoomFinderModal, setShowRoomFinderModal] = useState(false);
  const [roomFinderError, setRoomFinderError] = useState(false);
  const [roomFinderErrorMessage, setRoomFinderErrorMessage] = useState("");

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const includeSignUpSurvey = formMethods.watch("includeSignUpSurvey");
  const selectedRoom = formMethods.watch("room");
  const isHostedAtSlalom = formMethods.watch("isHostedAtSlalom");
  const isRoomRequired = formMethods.watch("isRoomRequired");

  const format = formMethods.watch("format");
  const { errors } = formMethods.formState;
  const hasErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    if (isRoomRequired === "no") {
      formMethods.setValue("room", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoomRequired]);

  useEffect(() => {
    if (isHostedAtSlalom === "no") {
      formMethods.setValue("isRoomRequired", "no");
      formMethods.setValue("room", null);
      formMethods.setValue("location", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHostedAtSlalom]);

  useEffect(() => {
    if (format === "Virtual") {
      formMethods.setValue("isRoomRequired", "no");
      formMethods.setValue("isHostedAtSlalom", "no");
      formMethods.setValue("room", null);
      formMethods.setValue("location", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format]);

  const toISOString = (timestamp: number) =>
    new Date(timestamp * 1000).toISOString();

  const compressAndConvertImage = async (imageFile: File) => {
    const options = {
      maxSizeMB: 3.0,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(imageFile, options);
    return await imageCompression.getDataUrlFromFile(compressedFile);
  };

  const createEventEmailPayload = (
    {
      title,
      description,
      startDateTime,
      endDateTime,
      format,
      eventOrganizerName,
      eventOrganizerEmail,
      location,
      recipientList,
    }: Event,
    room: Room | null
  ) => {
    const isInPersonOrHybrid = format === "In Person" || format === "Hybrid";

    return {
      action: "create",
      eventTitle: title,
      eventDescription: description,
      eventStartDateTime: toISOString(startDateTime),
      eventEndDateTime: toISOString(endDateTime),
      roomLocation: isInPersonOrHybrid ? room?.email || "" : "",
      eventInvitees: recipientList.join(";"),
      eventOrganizer: eventOrganizerName,
      eventOrganizerEmail: eventOrganizerEmail,
      eventFormat: format,
      eventImage: "",
      eventLocation: isInPersonOrHybrid ? room?.name || location : location,
    };
  };

  const sendEventEmail = async (event: Event, room: Room | null) => {
    const payload = createEventEmailPayload(event, room);
    await axiosAPI.post(TRIGGER_EVENT_EMAIL_ACTION(event.eventId), payload);
  };

  const createEventObj = (data: any, eventHero: string, status: string) => {
    return {
      ...data,
      description: `<div style="margin: 0;"><style>div > p { margin: 0; }</style>${DOMPurify.sanitize(
        data.description
      )}</div>`,
      startDateTime: combineDayJSToUnix(
        data.eventDate as Dayjs,
        data.startDateTime as Dayjs
      ),
      endDateTime: combineDayJSToUnix(
        data.eventDate as Dayjs,
        data.endDateTime as Dayjs
      ),
      location:
        isRoomRequired === "no" && isHostedAtSlalom === "yes"
          ? "399 Boylston St #1000, Boston, MA"
          : isRoomRequired === "yes" && data.room?.name
          ? data.room.name
          : data.location,
      recipientList: data.recipientList,
      roomName:
        isRoomRequired === "yes" && data.room?.name ? data.room.name : "",
      roomEmail:
        isRoomRequired === "yes" && data.room?.email ? data.room.email : "",
      eventHero,
      status,
      eventOrganizerName: userInfo.name,
      eventOrganizerTitle: userInfo.jobTitle,
      eventOrganizerEmail: userInfo.email,
      participantEmail: "NA",
      signUpCreator: status === "DRAFT" ? false : data.signUpCreator,
    };
  };

  const createEvent = async (
    newEventObj: any,
    room: Room | null,
    sendEmail: boolean = true,
    isPublish: boolean = true
  ) => {
    try {
      setLoading(true);
      const response = await axiosAPI.post(CREATE_EVENT(), newEventObj);
      const { result }: { result: Event } = response.data;

      if (sendEmail) {
        await sendEventEmail(result, room);
      }

      if (isPublish) {
        dispatch(getAllEvents());
        dispatch(getAllUserEvents(userInfo.email));
        dispatch({
          type: NEW_EVENT_CREATED,
          payload: { id: result.eventId },
        });
        navigate("/events");
      } else {
        dispatch(getAllEvents());
        dispatch(getAllUserEvents(userInfo.email));
        navigate(`/draft/${response.data.result.eventId}`, {
          state: {
            alert: {
              severity: "success",
              message: "Your draft has successfully been saved!",
            },
          },
        });
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.data;

      if (
        errorMessage.includes("Room is not available for the specified time")
      ) {
        setRoomFinderError(true);
        setRoomFinderErrorMessage(
          "The room that you have selected is no longer available. Please try again."
        );
      } else {
        setCreateEventError(true);
      }
    } finally {
      setLoading(false);
      window.scroll(0, 0);
    }
  };

  const consentModalCallback = async () => {
    const data = formMethods.getValues();

    setLoading(true);
    try {
      const eventImage = data.eventImageFile
        ? await compressAndConvertImage(data.eventImageFile as File)
        : "";
      const newEventObj = createEventObj(data, eventImage, "ACTIVE");
      await createEvent(newEventObj, data.room, true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveDraft = async () => {
    const data = formMethods.getValues();
    setLoading(true);
    try {
      const eventImage = data.eventImageFile
        ? await compressAndConvertImage(data.eventImageFile as File)
        : "";
      const newEventObj = createEventObj(data, eventImage, "DRAFT");
      await createEvent(newEventObj, data.room, false, false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<CreateEventForm> = async (data) => {
    if (
      data.isHostedAtSlalom === "yes" &&
      data.isRoomRequired === "yes" &&
      !data.room
    ) {
      setRoomFinderError(true);
      setRoomFinderErrorMessage(
        "Please select a room for this event or mark 'Would you like to request a room for this event to be in?' as no."
      );
    } else {
      setShowConsentModal(true);
    }
  };

  return (
    <>
      <EventOpsConsentModal
        open={showConsentModal}
        onClose={() => {
          setShowConsentModal(false);
        }}
        onConfirm={consentModalCallback}
      />
      <RoomFinderModal
        name={"room"}
        control={formMethods.control}
        setValue={formMethods.setValue}
        open={showRoomFinderModal}
        onClose={() => {
          setShowRoomFinderModal(false);
        }}
        roomFinderError={roomFinderError}
        roomFinderErrorMessage={roomFinderErrorMessage}
        setRoomFinderError={setRoomFinderError}
        setRoomFinderErrorMessage={setRoomFinderErrorMessage}
      />
      <DraftEventsModal
        open={showDraftEventsModal}
        onClose={() => {
          setShowDraftEventsModal(false);
        }}
      />
      <ClearFormModal
        open={showClearFormModal}
        onClose={() => {
          setShowClearFormModal(false);
        }}
        reset={formMethods.reset}
      />
      <PageContainer>
        {createEventError ? (
          <APIError
            onClick={() => {
              setCreateEventError(false);
            }}
          />
        ) : (
          <Styled.PaddedContainer>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                {roomFinderError && (
                  <Alert
                    severity="error"
                    sx={{ marginBottom: theme.spacing(2) }}
                  >
                    {roomFinderErrorMessage}
                  </Alert>
                )}
                <Styled.HeaderStack
                  direction={{ mobile: "column", desktop: "row" }}
                  width="100%"
                >
                  <Styled.HeaderText variant="h1" gutterBottom>
                    Create An Event
                  </Styled.HeaderText>
                  <Styled.SignUpCheckboxContainer>
                    <Typography> Sign me up for this event</Typography>
                    <Checkbox
                      name="signUpCreator"
                      control={formMethods.control}
                      defaultChecked
                    />
                  </Styled.SignUpCheckboxContainer>
                </Styled.HeaderStack>
                {selectUserEventsDraftLoading ? (
                  <Styled.SubtitleActionTextLoading variant="modalSubtitle">
                    {`Loading draft events...`}
                  </Styled.SubtitleActionTextLoading>
                ) : (
                  <Styled.SubtitleActionText
                    variant="modalSubtitle"
                    onClick={() => {
                      setShowDraftEventsModal(true);
                    }}
                  >
                    {`View draft events (${selectUserEventsDraft.length})`}
                  </Styled.SubtitleActionText>
                )}
                <DetailsSection
                  formMethods={formMethods}
                  formOptions={formOptions}
                  environment={environment}
                  userInfo={userInfo}
                  format={format}
                  isHostedAtSlalom={isHostedAtSlalom}
                  isRoomRequired={isRoomRequired}
                  roomFinderError={roomFinderError}
                  roomFinderErrorMessage={roomFinderErrorMessage}
                  selectedRoom={selectedRoom}
                  roomsLoading={roomsLoading}
                  setShowRoomFinderModal={setShowRoomFinderModal}
                />
                <PrimaryContactSection formMethods={formMethods} />
                <SignupSurveySection
                  formMethods={formMethods}
                  includeSignUpSurvey={includeSignUpSurvey}
                />
                <EventFeedbackSection formMethods={formMethods} />
                {loading ? (
                  <Styled.LoaderContainer>
                    <CircularProgress />
                  </Styled.LoaderContainer>
                ) : (
                  <Box>
                    <Box
                      display="flex"
                      justifyContent={{ mobile: "center", desktop: "end" }}
                    >
                      {hasErrors && (
                        <Styled.ErrorMessage>
                          Please correct all errors before publishing the event
                        </Styled.ErrorMessage>
                      )}
                      {roomFinderError && (
                        <Styled.ErrorMessage>
                          {roomFinderErrorMessage}
                        </Styled.ErrorMessage>
                      )}
                    </Box>
                    <Styled.FormActionStack
                      direction={{ mobile: "column", desktop: "row" }}
                      spacing={theme.spacing(2)}
                      width="100%"
                      alignItems={{ mobile: "flex-start", desktop: "flex-end" }}
                    >
                      <Styled.ActionTextButton
                        onClick={() => {
                          setShowClearFormModal(true);
                        }}
                      >
                        Clear Event Form
                      </Styled.ActionTextButton>
                      <Stack
                        direction={{ mobile: "column", desktop: "row" }}
                        spacing={theme.spacing(2)}
                        width="100%"
                        justifyContent="flex-end"
                      >
                        <Styled.ActionButton
                          variant="outlined"
                          onClick={saveDraft}
                        >
                          Save Event Draft
                        </Styled.ActionButton>
                        <Styled.ActionButton
                          variant="contained"
                          type="submit"
                          disabled={
                            (roomsLoading || roomFinderError) &&
                            isRoomRequired === "yes"
                          }
                        >
                          Publish Event
                        </Styled.ActionButton>
                      </Stack>
                    </Styled.FormActionStack>
                  </Box>
                )}
              </form>
            </FormProvider>
          </Styled.PaddedContainer>
        )}
      </PageContainer>
    </>
  );
};

export default CreateEventPage;
