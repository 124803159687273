import DOMPurify from "dompurify";
import { Event } from "../types/event";
import { UserState } from "../types/redux";
import _ from "lodash";

const getNewValue = (oldValue: any, newValue: any) => {
  if (_.isArray(oldValue)) {
    return !_.isEqual(oldValue, newValue) ? newValue : undefined;
  } else {
    return oldValue !== newValue ? newValue : undefined;
  }
};

export const prepareEditEventData = (
  data: any,
  startDateTime: any,
  endDateTime: any,
  newEventHero: any,
  event: Event,
  isRoomRequired: string,
  isHostedAtSlalom: string,
  placeholderFileName: string,
  userInfo: UserState,
  status?: string
) => {
  const format = getNewValue(event.format, data.format);
  const editEventData: any = {
    title: getNewValue(event.title, data.title),
    description: getNewValue(
      event.description,
      DOMPurify.sanitize(data.description)
    ),
    startDateTime: getNewValue(event.startDateTime, startDateTime),
    endDateTime: getNewValue(event.endDateTime, endDateTime),
    dressCode: getNewValue(event.dressCode, data.dressCode),
    admissionCost: getNewValue(event.admissionCost, data.admissionCost),
    format: getNewValue(event.format, data.format),
    businessAreaTags: getNewValue(
      event.businessAreaTags,
      data.businessAreaTags
    ),
    groupTags: getNewValue(event.groupTags, data.groupTags),
    teamTags: getNewValue(event.teamTags, data.teamTags),
    transportationParkingDetails: getNewValue(
      event.transportationParkingDetails,
      data.transportationParkingDetails
    ),
    feedbackLink: getNewValue(event.feedbackLink, data.feedbackLink),
    primaryContacts: getNewValue(event.primaryContacts, data.primaryContacts),
    includeTeamsLink: getNewValue(
      event.teamsLink !== "" ? "yes" : "no",
      data.includeTeamsLink
    ),
    teamsLink: getNewValue(event.teamsLink, data.teamsLink),
    signUpSurvey: getNewValue(event.signUpSurvey, data.signUpSurvey),
    roomName:
      format === "Virtual"
        ? getNewValue(event.roomName, "")
        : getNewValue(event.roomName, data.room?.name),
    roomEmail:
      format === "Virtual"
        ? getNewValue(event.roomEmail, "")
        : getNewValue(event.roomEmail, data.room?.email),
    isHostedAtSlalom: getNewValue(
      event.isHostedAtSlalom,
      data.isHostedAtSlalom
    ),
    location:
      isRoomRequired === "no" && isHostedAtSlalom === "yes"
        ? getNewValue(event.location, "399 Boylston St #1000, Boston, MA")
        : isRoomRequired === "yes" && data.room?.name
        ? getNewValue(event.location, data.room.name)
        : getNewValue(event.location, data.location),
    isRoomRequired: getNewValue(event.isRoomRequired, data.isRoomRequired),
    recipientList: (() => {
      const newValue = getNewValue(event.recipientList, data.recipientList);
      return Array.isArray(newValue) &&
        newValue.length === 1 &&
        newValue[0] === null
        ? null
        : newValue;
    })(),
    status: status,
  };

  if (data.eventImageFile && placeholderFileName === "FILE_CHANGED") {
    editEventData.eventHero = newEventHero;
    editEventData.eventOrganizerName = userInfo.name;
    editEventData.eventOrganizerTitle = userInfo.jobTitle;
    editEventData.eventOrganizerEmail = userInfo.email;
  } else if (placeholderFileName === "FILE_CHANGED" && !data.eventImageFile) {
    editEventData.eventHero = "";
  }

  return editEventData;
};
