import * as Styled from "./LiveEventPage.styles";

import { APIError, PageContainer, ToggleButton } from "../../components";
import { AttendanceTab, DetailsTab, PhotosTab } from "./Tabs";
import { Button, Skeleton } from "@mui/material";
import {
  getLiveEvent,
  getLiveEventAttendees,
  getLiveEventPhotos,
  refreshLiveEvent,
  refreshLiveEventAttendees,
  refreshLiveEventPhotos,
} from "../../store/actions/liveEventActions";
import { useEffect, useState } from "react";

import { Attendee } from "../../types/attendee";
import { JOIN_EVENT } from "../../services/endpoints";
import { LiveEventWelcomeModal } from "../../modals";
import LoadingModal from "../../modals/LoadingModal/LoadingModal";
import { RootState } from "../../store/store";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import axiosAPI from "../../services/axios";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const LiveEventPage = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { id } = useParams();
  const initialTab = 0;
  const [tab, setTab] = useState(initialTab);
  const [showLiveEventWelcome, setShowLiveEventWelcome] = useState(false);
  const [showCheckingInModal, setShowCheckingInModal] = useState(false);

  const { user, attendees, eventLoading, eventLoadingError, event } =
    useSelector((state: RootState) => ({
      user: state.user,
      attendees: state.liveEvent.attendees,
      eventLoading: state.liveEvent.loading,
      eventLoadingError: state.liveEvent.error,
      event: state.liveEvent.data,
    }));

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    const tabName = url.searchParams.get("tab");

    if (tabName === "photos") {
      setTab(1);
    } else if (tabName === "attendance") {
      setTab(2);
    } else {
      setTab(0);
    }

    url.searchParams.set("tab", tabName || "details");
    window.history.replaceState(null, "", url.href);
  }, []);

  const tabOptions = ["Details", "Photos", "Attendance"];

  useEffect(() => {
    const checkAttendees = async () => {
      const isCheckedIn = attendees.data.find(
        (attendee: Attendee) => attendee.participantEmail === user.email
      );

      if (!isCheckedIn && event && attendees.loading === false) {
        setShowCheckingInModal(true);
        const joinEventObj = {
          participantEmail: user.email,
          participantName: user.name,
          participantTitle: user.jobTitle,
        };
        await axiosAPI
          .post(JOIN_EVENT(event.eventId), joinEventObj)
          .then((response: any) => {
            setShowCheckingInModal(false);
            setShowLiveEventWelcome(true);
            dispatch(refreshLiveEventAttendees(event.eventId));
          })
          .catch((error) => {
            setShowCheckingInModal(false);
            setShowLiveEventWelcome(false);
          });
      }
    };
    if (id === event?.eventId) {
      checkAttendees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendees.data, event?.eventId]);

  useEffect(() => {
    if (id && id !== "") {
      if (id === event?.eventId) {
        dispatch(refreshLiveEvent(id));
        dispatch(refreshLiveEventPhotos(id));
        dispatch(refreshLiveEventAttendees(id));
      } else {
        dispatch(getLiveEvent(id));
        dispatch(getLiveEventAttendees(id));
        dispatch(getLiveEventPhotos(id));
      }

      // Then set up an interval to call them every minute
      const intervalId = setInterval(() => {
        dispatch(refreshLiveEvent(id));
        dispatch(refreshLiveEventPhotos(id));
        dispatch(refreshLiveEventAttendees(id));
      }, 60000); // 60000 milliseconds = 1 minute
      return () => {
        clearInterval(intervalId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    if (event && !eventLoading) {
      const currentTime = new Date();
      const startTime = new Date(event.startDateTime * 1000);
      const endTime = new Date(event.endDateTime * 1000);
      const fifteenMinutesBeforeStart = new Date(
        startTime.getTime() - 15 * 60 * 1000
      );
      const thirtyMinutesAfterEnd = new Date(
        endTime.getTime() + 30 * 60 * 1000
      );

      if (
        currentTime < fifteenMinutesBeforeStart ||
        currentTime > thirtyMinutesAfterEnd ||
        event.status !== "ACTIVE"
      ) {
        navigate("/events", {
          state: {
            alert: {
              severity: "error",
              message:
                "The event that you tried to access is either no longer live, was cancelled, or has not begun yet.",
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, navigate]);

  return (
    <PageContainer>
      {eventLoadingError ? (
        <APIError />
      ) : (
        <>
          <LoadingModal
            open={showCheckingInModal}
            onClose={() => {
              setShowCheckingInModal(false);
            }}
          />
          <LiveEventWelcomeModal
            open={showLiveEventWelcome}
            onClose={() => {
              window.history.replaceState(null, "");
              setShowLiveEventWelcome(false);
            }}
          />
          <Styled.Header>
            <Styled.HeaderStack
              direction={{
                mobile: "column",
                tablet: "row",
                desktop: "row",
              }}
              spacing={1}
              width="100%"
            >
              {eventLoading || !event ? (
                <Styled.HeaderTextSkeleton variant="rectangular"></Styled.HeaderTextSkeleton>
              ) : (
                <Styled.HeaderText variant="h1" gutterBottom>
                  {event.title}
                </Styled.HeaderText>
              )}
              {attendees.loading || !attendees.data ? (
                <Styled.HeaderActionContainer>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "48px",
                      width: "100%",
                    }}
                  ></Skeleton>
                </Styled.HeaderActionContainer>
              ) : (
                <Styled.HeaderActionContainer>
                  {/* <Button
                    variant="text"
                    startIcon={<LogoutIcon />}
                    onClick={() => {
                      console.log("leave event clicked");
                    }}
                  >
                    Leave Event
                  </Button> */}
                  {event && event?.feedbackLink && (
                    <Button
                      variant="text"
                      startIcon={<StarOutlineIcon />}
                      onClick={() => {
                        window.open(event?.feedbackLink, "_blank");
                      }}
                    >
                      Give Feedback
                    </Button>
                  )}
                </Styled.HeaderActionContainer>
              )}
            </Styled.HeaderStack>
            <ToggleButton
              option={tab}
              setOption={setTab}
              onClick={(index: number) => {
                setTab(index);
                navigate(`?tab=${tabOptions[index].toLowerCase()}`);
              }}
              options={tabOptions}
            ></ToggleButton>
          </Styled.Header>
          {tab === 0 ? (
            <DetailsTab />
          ) : tab === 1 ? (
            <PhotosTab />
          ) : (
            <AttendanceTab />
          )}{" "}
        </>
      )}
    </PageContainer>
  );
};

export default LiveEventPage;
