import "react-quill/dist/quill.snow.css"; // Import Quill's default CSS

import { Box, Typography, useTheme } from "@mui/material";
import { useRef, useState } from "react";

import { Controller } from "react-hook-form";
import MagicUrl from "quill-magic-url";
import ReactQuill from "react-quill";

// Register the MagicUrl module
ReactQuill.Quill.register("modules/magicUrl", MagicUrl);

type RichTextEditorProps = {
  name: string;
  control: any;
  label: string;
  setValue: any;
  required?: boolean;
  defaultValue?: string;
};

const RichTextEditor = ({
  name,
  control,
  setValue,
  required = false,
  label,
  defaultValue,
}: RichTextEditorProps) => {
  const [editorState, setEditorState] = useState(defaultValue || "");
  const editorRef = useRef<ReactQuill | null>(null);
  const theme = useTheme();

  const handleEditorChange = (content: string) => {
    setEditorState(content);
    setValue(name, content);
  };

  const modules = {
    toolbar: [
      [{ font: [] }, { size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
    magicUrl: true,
  };

  return (
    <>
      <style>
        {`
          .ql-editor {
            font-family: "Slalom Sans", sans-serif;
            font-size: 18px;
            height: 280px;
          }
          .quill-error .ql-container {
            border: 1px solid ${theme.colors.action.failure} !important;
          }
        `}
      </style>
      <Controller
        name={name}
        control={control}
        rules={{
          required: { value: required, message: `${label} is required` },
        }}
        render={({ field, fieldState }) => (
          <Box
            sx={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
            }}
          >
            <ReactQuill
              ref={editorRef}
              value={editorState}
              onChange={(content) => {
                handleEditorChange(content);
                field.onChange(content);
              }}
              modules={modules}
              className={fieldState.error ? "quill-error" : ""}
            />
            {fieldState.error && (
              <Typography
                color="error"
                variant="modalBody"
                sx={{ marginTop: theme.spacing(1) }}
              >
                {fieldState.error.message}
              </Typography>
            )}
          </Box>
        )}
      />
    </>
  );
};

export default RichTextEditor;
