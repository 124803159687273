import * as Styled from "../CreateEventPage.styles";

import {
  Box,
  CircularProgress,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DatePickerInput,
  FileInput,
  Input,
  MeetingRoom,
  RadioGroup,
  RichTextEditor,
  TimeRangePicker,
} from "../../../components";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const DetailsSection = ({
  formMethods,
  formOptions,
  environment,
  userInfo,
  format,
  isHostedAtSlalom,
  isRoomRequired,
  roomFinderError,
  roomFinderErrorMessage,
  selectedRoom,
  roomsLoading,
  setShowRoomFinderModal,
  eventDescription,
}: any) => {
  const theme = useTheme();
  return (
    <Styled.FormSectionContainer>
      <Typography variant="h3"> Details </Typography>
      <Input
        name="title"
        control={formMethods.control}
        label="Event Title"
        required
        placeholder="Enter title..."
        fullWidth
      />
      <Typography
        variant="body2"
        sx={{
          color: theme.colors.primary.slalomDarkBlue,
          marginTop: theme.spacing(2),
        }}
      >
        Event Description *
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: theme.colors.neutral.darkGray,
        }}
      >
        The event description will be added to the Outlook email invite. Use the
        space below to format your text, or paste text to this area from Word or
        Outlook. Currently you cannot add images, but that will be coming soon!
      </Typography>
      <RichTextEditor
        name="description"
        control={formMethods.control}
        setValue={formMethods.setValue}
        required
        label="Event Description"
        defaultValue={eventDescription || ""}
      />

      {/* Date & Time Section */}
      <Styled.InputStack
        direction={{ mobile: "column", desktop: "row" }}
        spacing={theme.spacing(3)}
        width="100%"
      >
        <DatePickerInput
          name="eventDate"
          control={formMethods.control}
          label="Event Date"
          required
        />
        <TimeRangePicker
          startName="startDateTime"
          endName="endDateTime"
          required
          control={formMethods.control}
        />
      </Styled.InputStack>

      {/* Dress Code, Cost, Event Image */}
      <Styled.InputStack
        direction={{ mobile: "column", desktop: "row" }}
        spacing={theme.spacing(3)}
        width="100%"
      >
        <Input
          name="dressCode"
          control={formMethods.control}
          label="Dress Code"
          placeholder="Enter title..."
          select
          selectOptions={formOptions.dressCode}
          sx={{
            width: "100%",
            [theme.breakpoints.up("desktop")]: { width: "33%" },
          }}
        />
        <Input
          name="admissionCost"
          control={formMethods.control}
          label="Cost"
          placeholder="0.00"
          inputProps={{ type: "number" }}
          startAdornment={
            <InputAdornment position="start">
              <Typography variant="body1" style={{ color: "#000000" }}>
                $
              </Typography>
            </InputAdornment>
          }
          sx={{
            width: "100%",
            [theme.breakpoints.up("desktop")]: { width: "33%" },
          }}
        />
        <FileInput
          name="eventImageFile"
          control={formMethods.control}
          label="Event Image"
          placeholder="900x400 png/jpg"
          sx={{
            width: "100%",
            [theme.breakpoints.up("desktop")]: { width: "33%" },
          }}
        />
      </Styled.InputStack>

      {/* Business Area(s), Group(s), Team(s) */}
      <Styled.InputStack
        direction={{ mobile: "column", desktop: "row" }}
        spacing={theme.spacing(3)}
        width="100%"
      >
        <Input
          name="businessAreaTags"
          control={formMethods.control}
          label="Business Area(s)"
          required
          select
          multiple
          selectOptions={formOptions.businessAreas}
          sx={{
            width: "100%",
            [theme.breakpoints.up("desktop")]: { width: "33%" },
          }}
        />
        <Input
          name="groupTags"
          control={formMethods.control}
          label="Group(s)"
          required
          select
          multiple
          selectOptions={formOptions.groups}
          sx={{
            width: "100%",
            [theme.breakpoints.up("desktop")]: { width: "33%" },
          }}
        />
        <Input
          name="teamTags"
          control={formMethods.control}
          label="Team(s)"
          required
          select
          multiple
          selectOptions={formOptions.teams}
          sx={{
            width: "100%",
            [theme.breakpoints.up("desktop")]: { width: "33%" },
          }}
        />
      </Styled.InputStack>

      <Input
        name="format"
        control={formMethods.control}
        label="Event Format"
        required
        select
        selectOptions={formOptions.eventFormats}
        fullWidth
      />

      {format !== "Virtual" && format !== "" && (
        <>
          <Typography variant="body2" style={{ marginTop: theme.spacing(2) }}>
            Will this event be hosted at Slalom?
          </Typography>
          <RadioGroup
            control={formMethods.control}
            name="isHostedAtSlalom"
            label=""
            row
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
          />
          {isHostedAtSlalom === "yes" ? (
            <>
              <Typography
                variant="body2"
                style={{ marginTop: theme.spacing(2) }}
              >
                Would you like to request a room for this event to be in?
              </Typography>
              <RadioGroup
                control={formMethods.control}
                name="isRoomRequired"
                label=""
                row
                options={[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ]}
              />
              {isRoomRequired === "yes" && (
                <>
                  {roomFinderError && (
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: theme.spacing(2),
                        color: theme.colors.action.failure,
                      }}
                    >
                      {roomFinderErrorMessage}
                    </Typography>
                  )}
                  {selectedRoom ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: theme.spacing(2),
                      }}
                    >
                      {roomsLoading && <CircularProgress size={24} />}
                      <MeetingRoom
                        room={selectedRoom}
                        actionText="Change Room"
                        onClick={() => setShowRoomFinderModal(true)}
                        watchedRoom={selectedRoom}
                        border={`1px solid ${theme.colors.neutral.softGray}`}
                        bypassShowAction
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: theme.spacing(2),
                        marginTop: theme.spacing(2),
                      }}
                    >
                      {roomsLoading && <CircularProgress size={24} />}
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowRoomFinderModal(true)}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.colors.primary.slalomDarkBlue,
                            textDecoration: "underline",
                          }}
                        >
                          Show available rooms
                        </Typography>
                        <ArrowForwardIcon
                          sx={{
                            color: theme.colors.primary.slalomDarkBlue,
                            position: "relative",
                            top: "2px",
                          }}
                        />
                      </Stack>
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Typography variant="h3"> Location </Typography>
              <Input
                name="location"
                control={formMethods.control}
                label="Location"
                required
                placeholder="Enter details..."
                fullWidth
              />
              <Input
                name="transportationParkingDetails"
                control={formMethods.control}
                label="Transportation & parking details"
                placeholder="Enter details..."
                fullWidth
                multiline
              />
            </>
          )}
        </>
      )}

      <Typography
        variant="h3"
        sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1) }}
      >
        {" "}
        Email Invitees{" "}
      </Typography>
      <Typography
        variant="subtitle1"
        style={{
          color: theme.colors.neutral.darkGray,
          marginBottom: theme.spacing(2),
        }}
      >
        Select the distribution list(s) you would like to invite to this event.
      </Typography>
      <Input
        name="recipientList"
        control={formMethods.control}
        label="Email Invitees"
        required
        select
        multiple
        selectOptions={[
          ...(environment !== "prod"
            ? [{ label: `Myself (${userInfo.email})`, value: userInfo.email }]
            : []),
          ...formOptions.distributionList
            .filter((item: any) =>
              environment !== "prod" ? !item.prod : item.prod
            )
            .map((item: any) => ({
              label: item.label,
              value: item.value,
            })),
        ]}
        removeMarginTop
        fullWidth
      />
    </Styled.FormSectionContainer>
  );
};

export default DetailsSection;
