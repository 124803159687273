const formOptions = {
  eventFormats: [
    {
      label: "Virtual",
      value: "Virtual",
    },
    {
      label: "In Person",
      value: "In Person",
    },
    {
      label: "Hybrid",
      value: "Hybrid",
    },
  ],
  dressCode: [
    {
      label: "Business",
      value: "Business",
    },
    {
      label: "Business Casual",
      value: "Business Casual",
    },
    {
      label: "Casual",
      value: "Casual",
    },
  ],
  businessAreas: [
    {
      label: "Capability",
      value: "Capability",
    },
    {
      label: "Accounts",
      value: "Accounts",
    },
    {
      label: "Corporate Citizenship",
      value: "Corporate Citizenship",
    },
    {
      label: "CoPs",
      value: "CoPs",
    },
    {
      label: "Office",
      value: "Office",
    },
  ],
  groups: [
    {
      label: "Technology",
      value: "Technology",
    },
    {
      label: "Business Transformation",
      value: "Business Transformation",
    },
    {
      label: "Delivery",
      value: "Delivery",
    },
    {
      label: "Life Sciences",
      value: "Life Sciences",
    },
    {
      label: "Cross Industry",
      value: "Cross Industry",
    },
    {
      label: "Financial Services and Insurance",
      value: "Financial Services and Insurance",
    },
    {
      label: "Consumer, Manufacturing, Technology",
      value: "Consumer, Manufacturing, Technology",
    },
    {
      label: "Portland, Maine",
      value: "Portland, Maine",
    },
    {
      label: "ID&E",
      value: "ID&E",
    },
    {
      label: "Partners for Good",
      value: "Partners for Good",
    },
    {
      label: "Health and Environment",
      value: "Health and Environment",
    },
    {
      label: "Network",
      value: "Network",
    },
    {
      label: "Healthcare",
      value: "Healthcare",
    },
    {
      label: "Retail",
      value: "Retail",
    },
    {
      label: "Public Sector and Impact",
      value: "Public Sector and Impact",
    },
    {
      label: "Financial Services",
      value: "Financial Services",
    },
    {
      label: "Operations",
      value: "Operations",
    },
    {
      label: "People Experience",
      value: "People Experience",
    },
  ],
  teams: [
    {
      label: "Application Development (App Dev)",
      value: "Application Development (App Dev)",
    },
    {
      label: "Salesforce (CRM)",
      value: "Salesforce (CRM)",
    },
    {
      label: "Digital Experience Management (DXM)",
      value: "Digital Experience Management (DXM)",
    },
    {
      label: "Data & Analytics (D&A)",
      value: "Data & Analytics (D&A)",
    },
    {
      label: "Business Strategy",
      value: "Business Strategy",
    },
    {
      label: "Customer Strategy",
      value: "Customer Strategy",
    },
    {
      label: "Experience Design",
      value: "Experience Design",
    },
    {
      label: "Organizational Effectiveness",
      value: "Organizational Effectiveness",
    },
    {
      label: "Process Optimization",
      value: "Process Optimization",
    },
    {
      label: "Strategy & Operations",
      value: "Strategy & Operations",
    },
    {
      label: "Technology Strategy",
      value: "Technology Strategy",
    },
    {
      label: "Aspire",
      value: "Aspire",
    },
    {
      label: "Horizons",
      value: "Horizons",
    },
    {
      label: "Reach",
      value: "Reach",
    },
    {
      label: "Unidos",
      value: "Unidos",
    },
    {
      label: "Veterans",
      value: "Veterans",
    },
    {
      label: "WLN",
      value: "WLN",
    },
    {
      label: "WIT",
      value: "WIT",
    },
    {
      label: "Pro-Bono",
      value: "Pro-Bono",
    },
    {
      label: "Green Scene",
      value: "Green Scene",
    },
    {
      label: "Wellness",
      value: "Wellness",
    },
    {
      label: "Communications",
      value: "Communications",
    },
    {
      label: "Social",
      value: "Social",
    },
    {
      label: "Other",
      value: "Other",
    },
  ],
  distributionList: [
    {
      label: "Slalom -- All -- Boston (slalomallboston@slalom.com)",
      value: "slalomallboston@slalom.com",
      prod: true,
    },
    {
      label: "Slalom -- All Hartford (slalomallhartford@slalom.com)",
      value: "slalomallhartford@slalom.com",
      prod: true,
    },
    {
      label:
        "Slalom -- Consulting -- New England (Slalom--Consulting--NewEngland@TWODEGREES1.onmicrosoft.com)",
      value: "Slalom--Consulting--NewEngland@TWODEGREES1.onmicrosoft.com",
      prod: true,
    },
    {
      label:
        "Slalom -- Consulting -- Boston (slalomconsultingboston@slalom.com)",
      value: "slalomconsultingboston@slalom.com",
      prod: true,
    },
    {
      label:
        "Slalom -- Consulting -- Hartford (slalomconsultinghartford@slalom.com)",
      value: "slalomconsultinghartford@slalom.com",
      prod: true,
    },
    {
      label: "Slalom -- Build -- Boston (slalomBuildBoston@slalom.com)",
      value: "slalomBuildBoston@slalom.com",
      prod: true,
    },
    {
      label: "Test Distribution List 1 (GatherTestingDL@slalom.com)",
      value: "GatherTestingDL@slalom.com",
      prod: false,
    },
    {
      label: "Test Distribution List 2 (GatherTestTwo@slalom.com)",
      value: "GatherTestTwo@slalom.com",
      prod: false,
    },
  ],
};

export default formOptions;
